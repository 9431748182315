import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Logo from "../images/logo.png"
import Layout from "../components/layout"

import HeaderImage from "../images/header.jpg"

const IndexPage = () => {
  return <Layout>

    <section className="bg-primary text-white text-center py-5" style={{
      backgroundImage: `linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(${HeaderImage})`,
      backgroundPosition: 'center 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <img src={Logo} style={{width: '20rem', marginBottom: '2rem'}} className="img-fluid" alt="Grass Top Games" />

      <p style={{fontSize: '1.5rem', fontFamily: 'Lato', fontWeight: 'bold'}}>Bring The Fun To Your Backyard</p>
    </section>

    <div style={{position: 'relative'}}>
      <div id="howitworks" style={{position: 'absolute', top: '-70px'}}></div>
    </div>

    <section className="py-5">
      <Container className="text-center">
        <h2 className="mb-4">How It Works</h2>

        <p className="mb-5" style={{fontSize: '1.25rem'}}>
          Grass Top Games wants to bring the fun to your backyard!<br />
          You select the games and we deliver.
        </p>

        <Row>
          <Col>
            <Link to="/games" className="text-white text-decoration-none">
              <div className="rounded-circle bg-secondary text-white mx-auto mb-3 d-flex align-items-center justify-content-center" style={{height: '8rem', width: '8rem'}}>
                <i className="fa fa-arrow-pointer fa-4x" />
              </div>
            </Link>

            <strong>Browse our game menu</strong>
            <p>Browse our <Link to="/games">menu of games and packages</Link>.</p>
          </Col>
          <Col>
            <div className="rounded-circle bg-secondary text-white mx-auto mb-3 d-flex align-items-center justify-content-center" style={{height: '8rem', width: '8rem'}}>
              <i className="fa fa-calendar-days fa-4x" />
            </div>

            <strong>Request your booking</strong>
            <p>Tell us your event date, package, and game choices.</p>
          </Col>
          <Col>
            <div className="rounded-circle bg-secondary text-white mx-auto mb-3 d-flex align-items-center justify-content-center" style={{height: '8rem', width: '8rem'}}>
              <i className="fa-solid fa-envelope fa-4x" />
            </div>
            <strong>Check your inbox</strong>
            <p>We'll get back to you within 24 hours.</p>
          </Col>
          <Col>
            <div className="rounded-circle bg-secondary text-white mx-auto mb-3 d-flex align-items-center justify-content-center" style={{height: '8rem', width: '8rem'}}>
              <i className="fa fa-dice fa-4x" />
            </div>
            <strong>Driveway delivery</strong>
            <p>Games will be delivered by 11 AM.</p>
          </Col>
          <Col>
            <div className="rounded-circle bg-secondary text-white mx-auto mb-3 d-flex align-items-center justify-content-center" style={{height: '8rem', width: '8rem'}}>
              <i className="fa fa-truck fa-4x" />
            </div>
            <strong>Game pick-up</strong>
            <p>Have games ready for pick up by 9 AM the next day.</p>
          </Col>
        </Row>
      </Container>
    </section>

  </Layout>
}

export default IndexPage
